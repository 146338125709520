import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-audio-player',
  standalone: false,
  templateUrl: './audio-player.component.html',
  styleUrl: './audio-player.component.scss'
})
export class AudioPlayerComponent implements OnInit, AfterViewInit {
  audioFiles: { src: string, title: string }[] = [
     // Música potente para empezar
     { src: 'assets/audio/Eye_of_the_Tiger-Survivor.mp3', title: 'Eye of the Tiger' },
     { src: 'assets/audio/Burning_heart-Survivor.mp3', title: 'Burning Heart' },
     { src: 'assets/audio/Hearts_on_fire-John_Cafferty.mp3', title: 'Hearts on Fire' },

     // Música lenta o romántica en el medio
     { src: 'assets/audio/Shes_Like_The_Wind.mp3', title: 'She\'s Like The Wind' },
     { src: 'assets/audio/bruno_mars-just_the_way_you_are.mp3', title: 'Just the Way You Are' },

     // Música potente para cerrar
     { src: 'assets/audio/DRIVE_THE_CARS.mp3', title: 'Drive the Cars' },
     { src: 'assets/audio/DIRTY_DANCING_Hungry_Eyes.mp3', title: 'Hungry Eyes' }
  ];
  currentTrackIndex: number = 0;
  audio = new Audio();
  isLoading = false;
  currentTrackTitle: string = '';
  currentProgress: number = 0; // Progreso actual de la canción
  private audioContext!: AudioContext;
  private analyser!: AnalyserNode;
  private canvas!: HTMLCanvasElement;
  private canvasContext!: CanvasRenderingContext2D;
  private dataArray!: Uint8Array;

  ngOnInit(): void {
    this.loadAudio();
    this.playAudio();
    this.audio.ontimeupdate = () => this.updateProgress(); // Actualiza el progreso
  }

  ngAfterViewInit(): void {
    this.canvas = document.getElementById('audio-visualizer') as HTMLCanvasElement;
    this.canvasContext = this.canvas.getContext('2d')!;
    this.setupAudioContext();
  }

  loadAudio(): void {
    this.audio.src = this.audioFiles[this.currentTrackIndex].src;
    this.currentTrackTitle = this.audioFiles[this.currentTrackIndex].title;
    this.audio.load();
  }

  setupAudioContext(): void {
    this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    const source = this.audioContext.createMediaElementSource(this.audio);
    this.analyser = this.audioContext.createAnalyser();
    source.connect(this.analyser);
    this.analyser.connect(this.audioContext.destination);

    this.analyser.fftSize = 256;
    const bufferLength = this.analyser.frequencyBinCount;
    this.dataArray = new Uint8Array(bufferLength);

    this.drawVisualizer();
  }

  drawVisualizer(): void {
    requestAnimationFrame(() => this.drawVisualizer());

    this.analyser.getByteTimeDomainData(this.dataArray);

    this.canvasContext.fillStyle = 'rgb(10, 10, 10)';
    this.canvasContext.fillRect(0, 0, this.canvas.width, this.canvas.height);

    this.canvasContext.lineWidth = 2;
    this.canvasContext.strokeStyle = 'rgb(255, 255, 255)';

    this.canvasContext.beginPath();

    const sliceWidth = this.canvas.width / this.dataArray.length;
    let x = 0;

    for (let i = 0; i < this.dataArray.length; i++) {
      const v = this.dataArray[i] / 128.0;
      const y = v * this.canvas.height / 2;

      if (i === 0) {
        this.canvasContext.moveTo(x, y);
      } else {
        this.canvasContext.lineTo(x, y);
      }

      x += sliceWidth;
    }

    this.canvasContext.lineTo(this.canvas.width, this.canvas.height / 2);
    this.canvasContext.stroke();
  }

  playAudio(): void {
    this.isLoading = true;
    this.audio.play().then(() => {
      this.isLoading = false;
      this.audioContext.resume().then(() => {
        console.log('Playback resumed successfully');
      });
    }).catch(error => {
      console.error('Error trying to play audio:', error);
      this.isLoading = false;
    });
  }

  pauseAudio(): void {
    this.audio.pause();
  }

  nextTrack(): void {
    this.currentTrackIndex = (this.currentTrackIndex + 1) % this.audioFiles.length;
    this.loadAudio();
    this.playAudio();
  }

  prevTrack(): void {
    this.currentTrackIndex = (this.currentTrackIndex - 1 + this.audioFiles.length) % this.audioFiles.length;
    this.loadAudio();
    this.playAudio();
  }

  updateProgress(): void {
    this.currentProgress = this.audio.currentTime;
  }

  onSeek(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const seekTime = parseFloat(inputElement.value);
    this.audio.currentTime = seekTime;
  }



}
