import { Component, OnInit, AfterViewInit } from '@angular/core';

import { trigger, state, style, animate, transition, stagger, query } from "@angular/animations"
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('bannerTrigger', [
      transition(":enter", [
        query("*", [
          style({ opacity: 0, transform: "translateX(-50px)" }),
          stagger(50, [
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" })
            )
          ])
        ])
      ])
    ])
  ]
})
export class BannerComponent implements OnInit, AfterViewInit {



  constructor(
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
  }

  //
  ngAfterViewInit() {
    this.initCanvasAnimation();
  }



  // initCanvasAnimation() {
  //   const canvas = document.getElementById('bannerCanvas') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');

  //   let particlesArray = [];
  //   const numberOfParticles = 150;
  //   canvas.width = canvas.offsetWidth;
  //   canvas.height = canvas.offsetHeight;

  //   // Crear Partículas (Estrellas)



  // class Particle {
  //     x: number;
  //     y: number;
  //     size: number;
  //     speedX: number;
  //     speedY: number;
  //     color: string;

  //     constructor() {
  //       this.x = Math.random() * canvas.width;
  //       this.y = Math.random() * canvas.height;
  //       this.size = Math.random() * 3 + 1;
  //       // Reducir la velocidad de movimiento lateral y vertical
  //       this.speedX = (Math.random() * 0.5 - 0.25) * 0.5; // Movimiento lateral más lento
  //       this.speedY = (Math.random() * 0.5 + 0.05) * 0.5; // Movimiento vertical más lento
  //       this.color = 'rgba(255, 255, 255, 0.8)';
  //     }

  //     update() {
  //       this.x += this.speedX;
  //       this.y += this.speedY;

  //       if (this.x > canvas.width || this.x < 0) this.speedX = -this.speedX;
  //       if (this.y > canvas.height || this.y < 0) this.speedY = -this.speedY;
  //     }

  //     draw() {
  //       ctx.beginPath();
  //       const spikes = 5;
  //       const outerRadius = this.size;
  //       const innerRadius = this.size / 2;

  //       let rotation = (Math.PI / 2) * 3;
  //       let x = this.x;
  //       let y = this.y;
  //       let step = Math.PI / spikes;

  //       ctx.moveTo(x, y - outerRadius);
  //       for (let i = 0; i < spikes; i++) {
  //         x = this.x + Math.cos(rotation) * outerRadius;
  //         y = this.y + Math.sin(rotation) * outerRadius;
  //         ctx.lineTo(x, y);
  //         rotation += step;

  //         x = this.x + Math.cos(rotation) * innerRadius;
  //         y = this.y + Math.sin(rotation) * innerRadius;
  //         ctx.lineTo(x, y);
  //         rotation += step;
  //       }

  //       ctx.lineTo(this.x, this.y - outerRadius);
  //       ctx.closePath();
  //       ctx.fillStyle = this.color;
  //       ctx.fill();
  //     }
  // }

  //   // Luna llena realista
  //   class Moon {
  //     x: number;
  //     y: number;
  //     size: number;
  //     opacity: number;
  //     craters: any[];

  //     constructor() {
  //       this.x = canvas.width - 150;
  //       this.y = 150;
  //       this.size = 80;
  //       this.opacity = 0.8;
  //       this.craters = this.generateCraters();
  //     }

  //     generateCraters() {
  //       const craters = [];
  //       const numCraters = Math.random() * 15 + 5;

  //       for (let i = 0; i < numCraters; i++) {
  //         const crater = {
  //           x: Math.random() * this.size * 2 - this.size,
  //           y: Math.random() * this.size * 2 - this.size,
  //           size: Math.random() * 15 + 5,
  //           depth: Math.random() * 0.5 + 0.2
  //         };
  //         craters.push(crater);
  //       }
  //       return craters;
  //     }

  //     drawCraters() {
  //       ctx.save();
  //       ctx.globalCompositeOperation = 'source-atop';
  //       ctx.fillStyle = 'rgba(0, 0, 0, 0.2)';

  //       this.craters.forEach(crater => {
  //         ctx.beginPath();
  //         ctx.arc(this.x + crater.x, this.y + crater.y, crater.size, 0, Math.PI * 2);
  //         ctx.fill();
  //       });

  //       ctx.restore();
  //     }

  //     draw() {
  //       ctx.save();
  //       ctx.globalAlpha = this.opacity;

  //       ctx.beginPath();
  //       ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
  //       ctx.fillStyle = 'rgba(230, 230, 230, 1)';
  //       ctx.fill();
  //       ctx.strokeStyle = 'rgba(200, 200, 200, 0.8)';
  //       ctx.stroke();

  //       this.drawCraters();

  //       ctx.restore();
  //     }
  //   }

  //   // Cometa
  //   class Comet {
  //     x: number;
  //     y: number;
  //     speedX: number;
  //     size: number;
  //     tailLength: number;
  //     active: boolean;

  //     constructor() {
  //       this.x = 0;
  //       this.y = Math.random() * canvas.height / 2; // Pasará por la parte superior
  //       this.speedX = Math.random() * 1.5 + 1; // Velocidad del cometa
  //       this.size = 5;
  //       this.tailLength = 50;
  //       this.active = true; // Indica si el cometa está activo
  //     }

  //     draw() {
  //       if (!this.active) return;

  //       ctx.save();

  //       // Dibujar la cola del cometa
  //       for (let i = 0; i < this.tailLength; i++) {
  //         ctx.beginPath();
  //         ctx.arc(this.x - i * 2, this.y, this.size - i * 0.1, 0, Math.PI * 2);
  //         ctx.fillStyle = `rgba(255, 255, 255, ${1 - i / this.tailLength})`;
  //         ctx.fill();
  //       }

  //       // Dibujar la cabeza del cometa
  //       ctx.beginPath();
  //       ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
  //       ctx.fillStyle = 'rgba(0, 255, 0, 1)'; // Cabeza del cometa verde
  //       ctx.fill();

  //       ctx.restore();
  //     }

  //     update() {
  //       if (!this.active) return;

  //       this.x += this.speedX;

  //       if (this.x > canvas.width + this.tailLength) {
  //         this.active = false; // Desactivar el cometa cuando sale de la pantalla
  //       }
  //     }
  //   }

  //   const moon = new Moon();
  //   let comet = new Comet(); // Inicialmente un solo cometa

  //   // Inicializar Partículas
  //   function init() {
  //     particlesArray = [];
  //     for (let i = 0; i < numberOfParticles; i++) {
  //       particlesArray.push(new Particle());
  //     }
  //   }

  //   // Animar las Partículas, la Luna y los Cometas
  //   function animate() {
  //     ctx.clearRect(0, 0, canvas.width, canvas.height);

  //     moon.draw();

  //     particlesArray.forEach(particle => {
  //       particle.update();
  //       particle.draw();
  //     });

  //     comet.update();
  //     comet.draw();

  //     requestAnimationFrame(animate);
  //   }

  //   init();
  //   animate();

  //   // Crear un nuevo cometa cada 15 segundos
  //   setInterval(() => {
  //     comet = new Comet(); // Crear un nuevo cometa
  //   }, 20000); // 15000 ms = 15 segundos

  //   // Ajustar el tamaño del canvas al cambiar el tamaño de la ventana
  //   window.addEventListener('resize', () => {
  //     canvas.width = canvas.offsetWidth;
  //     canvas.height = canvas.offsetHeight;
  //     moon.x = canvas.width - 150;
  //     init();
  //   });

  //   // Asegurarse de que el canvas esté detrás de otros elementos
  //   canvas.style.position = 'absolute';
  //   canvas.style.top = '0';
  //   canvas.style.left = '0';
  //   canvas.style.zIndex = '-1';
  // }

  initCanvasAnimation() {
    const canvas = document.getElementById('bannerCanvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    let particlesArray = [];
    const numberOfParticles = 150;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    const maxYPosition = canvas.height * 0.4; // Limitar la altura de las estrellas a un 40% del canvas

    // Crear Partículas (Estrellas)
    class Particle {
        x: number;
        y: number;
        size: number;
        opacity: number;
        fadeDirection: number;

        constructor() {
            this.x = Math.random() * canvas.width;
            this.y = Math.random() * maxYPosition; // Limitar la altura de la estrella
            this.size = Math.random() * 2 + 1; // Tamaño más pequeño
            this.opacity = Math.random();
            this.fadeDirection = Math.random() > 0.5 ? 1 : -1; // Dirección de desvanecimiento
        }

        update() {
            // Cambiar la opacidad para simular parpadeo
            this.opacity += this.fadeDirection * 0.01;
            if (this.opacity <= 0) {
                this.opacity = 0;
                this.fadeDirection = 1; // Cambiar la dirección del desvanecimiento
            }
            if (this.opacity >= 1) {
                this.opacity = 1;
                this.fadeDirection = -1; // Cambiar la dirección del desvanecimiento
            }
        }

        draw() {
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(this.x, this.y - this.size);
            for (let i = 0; i < 5; i++) {
                ctx.lineTo(
                    this.x + this.size * Math.cos((18 + i * 72) * Math.PI / 180),
                    this.y - this.size * Math.sin((18 + i * 72) * Math.PI / 180)
                );
                ctx.lineTo(
                    this.x + this.size * Math.cos((54 + i * 72) * Math.PI / 180) * 0.5,
                    this.y - this.size * Math.sin((54 + i * 72) * Math.PI / 180) * 0.5
                );
            }
            ctx.closePath();
            ctx.fillStyle = `rgba(255, 255, 255, ${this.opacity})`;
            ctx.fill();
            ctx.restore();
        }
    }

    // Luna llena realista
    class Moon {
        x: number;
        y: number;
        size: number;
        opacity: number;
        craters: any[];

        constructor() {
            this.x = canvas.width - 80; // Mover la luna un poco más a la derecha
            this.y = 150;
            this.size = 80;
            this.opacity = 0.8;
            this.craters = this.generateCraters();
        }

        generateCraters() {
            const craters = [];
            const numCraters = Math.random() * 15 + 5;

            for (let i = 0; i < numCraters; i++) {
                const crater = {
                    x: Math.random() * this.size * 2 - this.size,
                    y: Math.random() * this.size * 2 - this.size,
                    size: Math.random() * 15 + 5,
                    depth: Math.random() * 0.5 + 0.2
                };
                craters.push(crater);
            }
            return craters;
        }

        drawCraters() {
            ctx.save();
            ctx.globalCompositeOperation = 'source-atop';
            ctx.fillStyle = 'rgba(0, 0, 0, 0.2)';

            this.craters.forEach(crater => {
                ctx.beginPath();
                ctx.arc(this.x + crater.x, this.y + crater.y, crater.size, 0, Math.PI * 2);
                ctx.fill();
            });

            ctx.restore();
        }

        draw() {
            ctx.save();
            ctx.globalAlpha = this.opacity;

            ctx.beginPath();
            ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);
            ctx.fillStyle = 'rgba(230, 230, 230, 1)';
            ctx.fill();
            ctx.strokeStyle = 'rgba(200, 200, 200, 0.8)';
            ctx.stroke();

            this.drawCraters();

            ctx.restore();
        }
    }

    // Cometa
    class Comet {
        x: number;
        y: number;
        speedX: number;
        size: number;
        tailLength: number;
        active: boolean;

        constructor() {
            this.x = 0;
            this.y = Math.random() * maxYPosition; // Mantener el cometa a la misma altura que las estrellas
            this.speedX = 2; // Velocidad constante del cometa
            this.size = 3; // Tamaño más pequeño del cometa
            this.tailLength = 25; // Longitud de la cola
            this.active = true;
        }

        draw() {
            if (!this.active) return;

            ctx.save();

            // Dibujar la cola del cometa
            for (let i = 0; i < this.tailLength; i++) {
                ctx.beginPath();
                ctx.arc(this.x - i * 2, this.y, this.size - i * 0.1, 0, Math.PI * 2);
                ctx.fillStyle = `rgba(255, 255, 255, ${1 - i / this.tailLength})`;
                ctx.fill();
            }

            // Dibujar la cabeza del cometa
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
            ctx.fillStyle = 'rgba(0, 255, 0, 1)';
            ctx.fill();

            ctx.restore();
        }

        update() {
            if (!this.active) return;

            this.x += this.speedX;

            if (this.x > canvas.width) {
                this.active = false; // Desactivar el cometa cuando sale de la pantalla
            }
        }
    }

    const moon = new Moon();
    let comet = new Comet();

    // Inicializar Partículas
    function init() {
        particlesArray = [];
        for (let i = 0; i < numberOfParticles; i++) {
            particlesArray.push(new Particle());
        }
    }

    // Animar las Partículas, la Luna y los Cometas
    function animate() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        moon.draw();

        particlesArray.forEach(particle => {
            particle.update();
            particle.draw();
        });

        comet.update();
        comet.draw();

        requestAnimationFrame(animate);
    }

    init();
    animate();

    // Crear un nuevo cometa cada 20 segundos
    setInterval(() => {
        comet = new Comet();
    }, 20000);

    // Ajustar el tamaño del canvas al cambiar el tamaño de la ventana
    window.addEventListener('resize', () => {
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        moon.x = canvas.width - 120; // Mantener la luna a la derecha
        init();
    });

    canvas.style.position = 'absolute';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.zIndex = '-1';
}








}
