<div class="container_aws pb-2">
  <!-- <canvas id="starCanvas"></canvas> -->
  <h4 class="p-2" data-aos="fade-up">AWS re/Start Graduate</h4>
  <div class="text-center mt-4 " data-aos="fade-up" data-aos-duration="2000">
    <div data-iframe-width="200" data-iframe-height="270" data-share-badge-id="0b5ac6d0-0f15-4dc6-b2ec-d7c0e1b3dd9d" data-share-badge-host="https://www.credly.com">
    </div>
  </div>
</div>



