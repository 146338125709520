<div class="audio-player text-center pt-2">
  <i class="fa fa-music py-2" aria-hidden="true"></i>
  <p class="current-track-title">{{ currentTrackTitle }}</p>

  <button class="btn btn-light mx-2 btn-sm" (click)="prevTrack()">
    <i class="fas fa-backward"></i> Previous
  </button>

  <button class="btn btn-success mx-2 btn-sm" (click)="playAudio()" [disabled]="isLoading">
    <ng-container *ngIf="!isLoading; else loading">
      <i class="fas fa-play"></i> Play
    </ng-container>
    <ng-template #loading>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </ng-template>
  </button>

  <button class="btn btn-warning mx-2 btn-sm" (click)="pauseAudio()">
    <i class="fas fa-pause"></i> Pause
  </button>

  <button class="btn btn-light mx-2 btn-sm" (click)="nextTrack()">
    <i class="fas fa-forward"></i> Next
  </button>
  <div class="pt-2">
    <canvas id="audio-visualizer" width="500" height="80"></canvas>
  </div>

</div>
