<section class="section about" id='about'>
    <div class="container">
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>01.</span> {{"AboutMe.Title" | translate}}
                </h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p *ngFor='let text of "AboutMe.Paragraphs" | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                        </p>
                        <ul class="skills-list" data-aos="fade-up">
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Angular </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">RxJS </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">HTML &amp; CSS </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Bootstrap </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Node.js Express </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Tailwind CSS </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Java / Spring-boot </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">POO</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Quarkus </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">CI / CD </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">TypeScript </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Mysql</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">MongoDB Atlas</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">PostgreSQL</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Oracle SQL</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">AWS / GCP</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Docker</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Junit5 / Mockito</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">TDD</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Python </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">JavaScript </span></li>

                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                    <div class="about-img-container">
                        <img (click)='analyticsService.sendAnalyticEvent("click_image", "about", "image")' class="rounded img-hover-effect" width="400" height="300" src="assets/images/fondo.jpg" alt="Image Sobre mí">
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

