import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-aws',
  standalone: false,
  templateUrl: './aws.component.html',
  styleUrl: './aws.component.scss'
})
export class AwsComponent implements OnInit, AfterViewInit{

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = '//cdn.credly.com/assets/utilities/embed.js';
    script.async = true;
    this.renderer.appendChild(document.body, script);
  }

   // ESTRELLAS CANVAS
   ngAfterViewInit() {
    this.initStarCanvasAnimation();

  }

  initStarCanvasAnimation() {
    const canvas = document.getElementById('starCanvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    if (!ctx) return;

    let particlesArray: Particle[] = [];
    const numberOfParticles = 300; // Aumentar la cantidad de estrellas para un efecto más denso

    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    // Crear Partículas (Estrellas)
    class Particle {
        x: number;
        y: number;
        size: number;
        opacity: number;
        fadeDirection: number;
        speed: number;
        direction: number;

        constructor() {
            this.x = Math.random() * canvas.width;
            this.y = Math.random() * canvas.height; // Usar altura completa
            this.size = Math.random() * 1 + 0.2; // Tamaño más pequeño
            this.opacity = Math.random();
            this.fadeDirection = Math.random() > 0.5 ? 1 : -1; // Dirección de desvanecimiento
            this.speed = Math.random() * 0.1 + 0.05; // Movimiento más lento
            this.direction = Math.random() * Math.PI * 2; // Dirección de movimiento
        }

        update() {
            // Cambiar la opacidad para simular parpadeo
            this.opacity += this.fadeDirection * 0.01;
            if (this.opacity <= 0) {
                this.opacity = 0;
                this.fadeDirection = 1;
            }
            if (this.opacity >= 1) {
                this.opacity = 1;
                this.fadeDirection = -1;
            }

            // Movimiento de las estrellas
            this.x += Math.cos(this.direction) * this.speed;
            this.y += Math.sin(this.direction) * this.speed;

            // Reposicionar las estrellas cuando salgan del canvas
            if (this.x > canvas.width) this.x = 0;
            if (this.x < 0) this.x = canvas.width;
            if (this.y > canvas.height) this.y = 0;
            if (this.y < 0) this.y = canvas.height;
        }

        draw() {
            ctx.save();
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
            ctx.fillStyle = `rgba(255, 255, 255, ${this.opacity})`;
            ctx.fill();
            ctx.restore();
        }
    }

    function init() {
        particlesArray = [];
        for (let i = 0; i < numberOfParticles; i++) {
            particlesArray.push(new Particle());
        }
    }

    function animate() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        particlesArray.forEach(particle => {
            particle.update();
            particle.draw();
        });

        // Añadir un efecto de nebulosa sutil
        ctx.save();
        ctx.globalCompositeOperation = 'overlay'; // Mezclar con el canvas
        const gradient = ctx.createRadialGradient(canvas.width / 2, canvas.height / 2, 0, canvas.width / 2, canvas.height / 2, canvas.width / 2);
        gradient.addColorStop(0, 'rgba(255, 255, 255, 0.2)'); // Blanco suave para contrastar con el fondo
        gradient.addColorStop(1, 'rgba(0, 0, 0, 0.4)'); // Negro semi-transparente
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.restore();

        requestAnimationFrame(animate);
    }

    window.addEventListener('resize', () => {
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        init();
    });

    init();
    animate();
}






}
