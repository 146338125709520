<footer>
  <div [@animateFooter]>
    <ul class="footer-left-bar d-none d-md-block">
      <li>
        <a (click)='analyticsService.sendAnalyticEvent("click_credly", "footer", "credly")'
          href="https://www.credly.com/users/abel-acevedo" target='_blank'>
          <i class="fas fa-certificate"></i>
        </a>
      </li>
      <!-- <li>
        <a (click)='analyticsService.sendAnalyticEvent("click_twitter", "footer", "twitter")'
          href="https://x.com/AbelFerAcevedo" target="_blank">
          <i class="fab fa-twitter"></i>
        </a>
      </li> -->
      <li>
        <a (click)='analyticsService.sendAnalyticEvent("click_github", "footer", "github")'
          href="https://github.com/Abel3581" target='_blank'>
          <i class="fab fa-github"></i>
        </a>
      </li>
      <li>
        <a (click)='analyticsService.sendAnalyticEvent("click_linkedin", "footer", "linkedin")'
          href="https://www.linkedin.com/in/abel-fernando-acevedo" target='_blank'>
          <i class="fab fa-linkedin-in"></i>
        </a>
      </li>
    </ul>
    <div class="footer-right-bar d-none d-md-block">
      <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "footer", "email")'
        href="mailto:marzoa3581@gmail.com" target='_blank'>marzoa3581&#64;gmail.com </a>
    </div>
  </div>
  <div class="footer-credits text-center">
    <a (click)='analyticsService.sendAnalyticEvent("click_github_portfolio_andresjose", "footer", "click")'
      href="https://www.abelacevedo.com.ar" target="_blank" rel="nofollow noopener noreferrer">
      <div>© 2024 Copyright: Abel Fernando Acevedo</div>
    </a>
    <a (click)='analyticsService.sendAnalyticEvent("click_github_portfolio_brittany", "footer", "click")'
      rel="nofollow noopener noreferrer">
      <div class="mt-2">Designed by Brittany Chiang</div>
    </a>
  </div>

</footer>
